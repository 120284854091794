import React, { useCallback, useEffect, useState } from 'react';
import useSound from "use-sound"; // for handling the sound
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai"; // icons for play and pause
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi"; // icons for next and previous track
import { IconContext } from "react-icons"; // for customazing the icons

function MusicPlayer(props) {

const [isPlaying, setIsPlaying] = useState(null);

const volume = 0.4;

const [play, { pause, duration, sound }] = useSound(props.trackData.trackT, {volume});

const playingButton = () => {
    if (isPlaying) {
      pause(); // this will pause the audio
      setIsPlaying(false);
    } else {
      play(); // this will play the audio
      setIsPlaying(true);
    }
  };

  const rotate = () => {
    pause(); // this will pause the audio
    setIsPlaying(false);
    props.updateFunc();
  }

return (
<div className="card">
  <img className="card-img-top height-limited" src={props.trackData.imageT} alt={props.imageAlt}/>
  <div class="card-body">
  <h5 className="card-title fancy">{props.trackData.descT}</h5>
  <div>
        {!isPlaying ? (
          <button className="playButton" onClick={playingButton}>
            <IconContext.Provider value={{ size: "3em", color: "#555d69" }}>
              <AiFillPlayCircle />
            </IconContext.Provider>
          </button>
        ) : (
          <button className="playButton" onClick={playingButton}>
            <IconContext.Provider value={{ size: "3em", color: "#555d69" }}>
              <AiFillPauseCircle />
            </IconContext.Provider>
          </button>
        )}
        <button className="playButton" onClick={rotate}>
          <IconContext.Provider value={{ size: "3em", color: "#555d69" }}>
            <BiSkipNext />
          </IconContext.Provider>
        </button>
        </div>
      </div>
    </div>
)
}

export default MusicPlayer;