import React, { useRef, useState } from 'react';
import Home from './Home';
import Console from './Console';
import Music from './Music';
import './CV.css';

function CVFrame() {

const [page, setPage] = useState(<Home/>);
var mutex = false;
const [show, setShow] = useState(true);
const toggleShow = () => setShow(!show);

const changePage = (target) => {
  if (page.type === target.type) {
    return;
  }
  if (mutex == false) {
    mutex = true;
    setShow(false);
    setTimeout(() => {  setPage(target); mutex=false; setShow(true);}, 500);
  }
}

const [display, setDisplay] = useState("displayed");
const [consDisplay, setConsDisplay] = useState(<p/>);

const reset = () => {
  setDisplay("displayed"); 
  setConsDisplay(<p/>);
  changePage(<Home/>);
}

const activateConsole = () => {
  setDisplay("no-display"); 
  setConsDisplay(<Console returnCommand={reset}/>);
}

return (
    <div>
    {consDisplay}
    <div className={display}>
        <div className="row fixed-top shadoww toppbar">
        <div className="col col-12">
        <nav clasName="navbar navbar-expand-lg py-2">
            <div className="container-fluid">
                <a className="leftaligned navbar-brand titles selectable-color" href="#" onClick={() => changePage(<Home/>)}>I . W . R . Lombard</a>
                <button className="rightaligned btn btn-outline-light contactbttn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  Get in contact
                </button>
              </div>
        </nav>
        </div>
        <div className='row'>
        <div className="col col-12">
          <nav className="navbar navbar-expand-lg py-2">
              <div className="container-fluid">
                  <a className="navbar-brand titles" href="#"></a>
                  <button className="navbar-toggler my-auto bgndbttncolour" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className=" fixcollapse collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto">
                      <li className="nav-item nondisable">
                        <a className="sanstitles nav-link text-end selectable-color" aria-current="page" href="#" onClick={() => changePage(<Home/>)}><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">Home</span></a>
                      </li>
                      <li className="nav-item">
                        <a className="sanstitles nav-link text-end selectable-color" href="#" onClick={activateConsole}><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">Code</span></a>
                      </li>
                      <li className="nav-item nondisable">
                        <a className="sanstitles nav-link text-end selectable-color" aria-current="page" href="#" onClick={() => changePage(<Music/>)}><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">Music</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
          </nav>
          </div> 
          </div>
    </div>
    <div class="empty-space">
      <hr/>
      <hr/>
      <hr/>
      <hr/>
      <hr/>
      <div class="wavedivformat">
        <div class="custom-shape-divider-top-1693047837">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
          </svg>
      </div>
      </div>
    </div>
    {page}
    <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title contacttitle" id="offcanvasExampleLabel">Contact</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <br/>
        <div>
          <i className="fa-solid fa-at"></i> <a className="contactlinktext" href = "mailto: isaac.lombard@gmail.com">isaac.lombard@gmail.com</a>
        </div>
        <br/>
        <div>
          <i className="fa-solid fa-phone"></i> <a className="contactlinktext" href="tel:0433003851">0433 003 851</a>
        </div>
      </div>
    
    </div>
    <footer className="footer-style">
        <p className='white-text'> Isaac L 2023</p>
    </footer>
    </div>
    </div>
);
}

export default CVFrame;