import React, {useEffect, useState } from 'react';

function SelfMultiplyText(props) {

    const bee = [`
    According to all known laws
    of aviation,
`,`
    there is no way a bee
    should be able to fly.
`,`
    Its wings are too small to get
    its fat little body off the ground.
`,`
    The bee, of course, flies anyway
`,`
    because bees don't care
    what humans think is impossible.
`,`
    Yellow, black. Yellow, black.
    Yellow, black. Yellow, black.
`,`
    Ooh, black and yellow!
    Let's shake it up a little.
`,``,`
    Ooming!
`,`
    Hang on a second.
`,`
    Hello?
`,`
    - Barry?
    - Adam?
`,`
    - Oan you believe this is happening?
    - I can't. I'll pick you up.
`,`
    Looking sharp.
`,`
    Use the stairs. Your father
    paid good money for those.
`,`
    Sorry. I'm excited.
`,`
    Here's the graduate.
    We're very proud of you, son.
`,`
    A perfect report card, all B's.
`,`
    Very proud.
`,`
    Ma! I got a thing going here.
`,`
    - You got lint on your fuzz.
    - Ow! That's me!
`,`
    - Wave to us! We'll be in row 118,000.
    - Bye!
`,`
    Barry, I told you,
    stop flying in the house!
`,`
    - Hey, Adam.
    - Hey, Barry.
`,`
    - Is that fuzz gel?
    - A little. Special day, graduation.
`,`
    Never thought I'd make it.
`,`
    Three days grade school,
    three days high school.
`,`
    Those were awkward.
`,`
    Three days college. I'm glad I took
    a day and hitchhiked around the hive.
`,`
    You did come back different.
`,`
    - Hi, Barry.
    - Artie, growing a mustache? Looks good.
`,`
    - Hear about Frankie?
    - Yeah.
`,`
    - You going to the funeral?
    - No, I'm not going.
`,`
    Everybody knows,
    sting someone, you die.
`,`
    Don't waste it on a squirrel.
    Such a hothead.
`,`
    I guess he could have
    just gotten out of the way.
`,`
    I love this incorporating
    an amusement park into our day.
`,`
    That's why we don't need vacations.
`,`
    Boy, quite a bit of pomp...
    under the circumstances.
`,`
    - Well, Adam, today we are men.
    - We are!
`,`
    - Bee-men.
    - Amen!
`,`
    Hallelujah!
`,`
    Students, faculty, distinguished bees,
`,`
    please welcome Dean Buzzwell.
`,`
    Welcome, New Hive Oity
    graduating class of...
`,`
    ...9:15.
`,`
    That concludes our ceremonies.
`,`
    And begins your career
    at Honex Industries!
`,`
    Will we pick ourjob today?
`,`
    I heard it's just orientation.
`,`
    Heads up! Here we go.
`,`
    Keep your hands and antennas
    inside the tram at all times.
`,`
    - Wonder what it'll be like?
    - A little scary.
`,`
    Welcome to Honex,
    a division of Honesco
`,`
    and a part of the Hexagon Group.
`,`
    This is it!
`,`
    Wow.
`,`
    Wow.
`,`
    We know that you, as a bee,
    have worked your whole life
`,`
    to get to the point where you
    can work for your whole life.
`,`
    Honey begins when our valiant Pollen
    Jocks bring the nectar to the hive.
`,`
    Our top-secret formula
`,`
    is automatically color-corrected,
    scent-adjusted and bubble-contoured
`,`
    into this soothing sweet syrup
`,`
    with its distinctive
    golden glow you know as...
`,`
    Honey!
`,`
    - That girl was hot.
    - She's my cousin!
`,`
    - She is?
    - Yes, we're all cousins.
`,`
    - Right. You're right.
    - At Honex, we constantly strive
`,`
    to improve every aspect
    of bee existence.
`,`
    These bees are stress-testing
    a new helmet technology.
`,`
    - What do you think he makes?
    - Not enough.
`,`
    Here we have our latest advancement,
    the Krelman.
`,`
    - What does that do?
    - Oatches that little strand of honey
`,`
    that hangs after you pour it.
    Saves us millions.
`,`
    Oan anyone work on the Krelman?
`,`
    Of course. Most bee jobs are
    small ones. But bees know
`,`
    that every small job,
    if it's done well, means a lot.
`,`
    But choose carefully
`,`
    because you'll stay in the job
    you pick for the rest of your life.
`,`
    The same job the rest of your life?
    I didn't know that.
`,`
    What's the difference?
`,`
    You'll be happy to know that bees,
    as a species, haven't had one day off
`,`
    in 27 million years.
`,`
    So you'll just work us to death?
`,`
    We'll sure try.
`,`
    Wow! That blew my mind!
`,`
    "What's the difference?"
    How can you say that?
`,`
    One job forever?
    That's an insane choice to have to make.
`,`
    I'm relieved. Now we only have
    to make one decision in life.
`,`
    But, Adam, how could they
    never have told us that?
`,`
    Why would you question anything?
    We're bees.
`,`
    We're the most perfectly
    functioning society on Earth.
`,`
    You ever think maybe things
    work a little too well here?
`,`
    Like what? Give me one example.
`,`
    I don't know. But you know
    what I'm talking about.
`,`
    Please clear the gate.
    Royal Nectar Force on approach.
`,`
    Wait a second. Oheck it out.
`,`
    - Hey, those are Pollen Jocks!
    - Wow.
`,`
    I've never seen them this close.
`,`
    They know what it's like
    outside the hive.
`,`
    Yeah, but some don't come back.
`,`
    - Hey, Jocks!
    - Hi, Jocks!
`,`
    You guys did great!
`,`
    You're monsters!
    You're sky freaks! I love it! I love it!
`,`
    - I wonder where they were.
    - I don't know.
`,`
    Their day's not planned.
`,`
    Outside the hive, flying who knows
    where, doing who knows what.
`,`
    You can'tjust decide to be a Pollen
    Jock. You have to be bred for that.
`,`
    Right.
`,`
    Look. That's more pollen
    than you and I will see in a lifetime.
`,`
    It's just a status symbol.
    Bees make too much of it.
`,`
    Perhaps. Unless you're wearing it
    and the ladies see you wearing it.
`,`
    Those ladies?
    Aren't they our cousins too?
`,`
    Distant. Distant.
`,`
    Look at these two.
`,`
    - Oouple of Hive Harrys.
    - Let's have fun with them.
`,`
    It must be dangerous
    being a Pollen Jock.
`,`
    Yeah. Once a bear pinned me
    against a mushroom!
`,`
    He had a paw on my throat,
    and with the other, he was slapping me!
`,`
    - Oh, my!
    - I never thought I'd knock him out.
`,`
    What were you doing during this?
`,`
    Trying to alert the authorities.
`,`
    I can autograph that.
`,`
    A little gusty out there today,
    wasn't it, comrades?
`,`
    Yeah. Gusty.
`,`
    We're hitting a sunflower patch
    six miles from here tomorrow.
`,`
    - Six miles, huh?
    - Barry!
`,`
    A puddle jump for us,
    but maybe you're not up for it.
`,`
    - Maybe I am.
    - You are not!
`,`
    We're going 0900 at J-Gate.
`,`
    What do you think, buzzy-boy?
    Are you bee enough?
`,`
    I might be. It all depends
    on what 0900 means.
`,`
    Hey, Honex!
`,`
    Dad, you surprised me.
`,`
    You decide what you're interested in?
`,`
    - Well, there's a lot of choices.
    - But you only get one.
`,`
    Do you ever get bored
    doing the same job every day?
`,`
    Son, let me tell you about stirring.
`,`
    You grab that stick, and you just
    move it around, and you stir it around.
`,`
    Well, I guess I'll go home now
`,`
    and just leave this nice honey out,
    with no one around.
`,`
    You're busted, box boy!
`,`
    I knew I heard something.
    So you can talk!
`,`
    I can talk.
    And now you'll start talking!
`,`
    Where you getting the sweet stuff?
    Who's your supplier?
`,`
    I don't understand.
    I thought we were friends.
`,`
    The last thing we want
    to do is upset bees!
`,`
    You're too late! It's ours now!
`,`
    You, sir, have crossed
    the wrong sword!
`,`
    You, sir, will be lunch
    for my iguana, Ignacio!
`,`
    Where is the honey coming from?
`,`
    Tell me where!
`,`
    Honey Farms! It comes from Honey Farms!
`,`
    Orazy person!
`,`
    What horrible thing has happened here?
`,`
    These faces, they never knew
    what hit them. And now
`,`
    they're on the road to nowhere!
`,`
    Just keep still.
`,`
    What? You're not dead?
`,`
    Do I look dead? They will wipe anything
    that moves. Where you headed?
`,`
    To Honey Farms.
    I am onto something huge here.
`,`
    I'm going to Alaska. Moose blood,
    crazy stuff. Blows your head off!
`,`
    I'm going to Tacoma.
`,`
    - And you?
    - He really is dead.
`,`
    All right.
`,`
    Uh-oh!
`,`
    - What is that?!
    - Oh, no!
`,`
    - A wiper! Triple blade!
    - Triple blade?
`,`
    Jump on! It's your only chance, bee!
`,`
    I was already a blood-sucking parasite.
    All I needed was a briefcase.
`,`
    Have a great afternoon!
`,`
    Barry, I just got this huge tulip order,
    and I can't get them anywhere.
`,`
    No problem, Vannie.
    Just leave it to me.
`,`
    You're a lifesaver, Barry.
    Oan I help who's next?
`,`
    All right, scramble, jocks!
    It's time to fly.
`,`
    Thank you, Barry!

    
    That bee is living my life!
`,`
    Let it go, Kenny.
`,`
    - When will this nightmare end?!
    - Let it all go.
`,`
    - Beautiful day to fly.
    - Sure is.
`,`
    Between you and me,
    I was dying to get out of that office.
`,`
    You have got
    to start thinking bee, my friend.
`,`
    - Thinking bee!
    - Me?
`,`
    Hold it. Let's just stop
    for a second. Hold it.
`,`
    I'm sorry. I'm sorry, everyone.
    Oan we stop here?
`,`
    I'm not making a major life decision
    during a production number!
`,`
    All right. Take ten, everybody.
    Wrap it up, guys.
`,`
    I had virtually no rehearsal for that.
    `, ``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,``,];

    const [current40, set40] = useState([]);

    useEffect(() => {
        setTimeout(() => {
        for (let index = 0; index < bee.length; index++) {
        setTimeout(() => {
            if (current40.length > 40) {
                current40.shift();
            }
            var t = bee[index];
            if (t !== ``) {
                t = (Math.random() + 1).toString(36).substring(6) + t;
            }
            current40.push({text: t, id: index});
            const new40 = current40.map((x) => x);
            set40(new40);
        }, 15 * (index) + (800 * ((3 * index ^ 2.5) / (index ^ 5))));
        }
    }, 1500);
    }, []);

    return (
        <div>
            {current40.map((beesy) => 
                <p key={beesy.id}>{beesy.text}</p>
            )
            }
        </div>
    )
}

export default SelfMultiplyText;