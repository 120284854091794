import React, { useCallback, useEffect, useState } from 'react';

function Parallax(props) {

    const variant = props.specificClass + " parallax brick mask-a";

    return (
        <div className="relativeclassgeneral">

        <div className={variant}>
    </div>

    </div>
    );
}

export default Parallax;