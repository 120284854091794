import React, {useEffect, useState } from 'react';
import './Console.css';
import Vim from './Vim';
import Loading from './Loading';
import SelfMultiplyText from './SelfMultiplyText';

function Console(props) {

    
    const phases = [(<SelfMultiplyText/>), (<Loading/>), (<Vim returnCommand={props.returnCommand}/>)]
    const [consolePhase, setConsolePhase] = useState(phases[0]);

    useEffect(() => {
        setTimeout(() => {
            setConsolePhase(phases[1]);
        }, 8000);
        setTimeout(() => {
            setConsolePhase(phases[2]);
        }, 17000);
    }, []);

    return (
        <div>
        <div className='console-bgnd'>
            {consolePhase}
        </div>
        </div>
    );
}

export default Console;