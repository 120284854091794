import React, {useState } from 'react';


function Loading(props) {
    return (
        <div id="cube">
            <div className="pos1">L</div>
            <div className="pos3">O</div>
            <div className="pos5">A</div>
            <div className="pos7">D</div>
            <div className="pos2">I</div>
            <div className="pos4">N</div>
            <div className="pos6">G</div>
            <div className="pos8">*</div>
        </div>

    )
}

export default Loading;
