import React, { useRef, useEffect, useState } from 'react';
import Parallax from './Parallax';
import MusicPlayer from './MusicPlayer';
import FadeIn from "react-fade-in";

function Music() {

  const [isIntersecting, setIsIntersecting] = useState(false);

  const ref = useRef(null);


  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    },
    { rootMargin: "300px" }
  );
  observer.observe(ref.current);
  return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      ref.current.querySelectorAll("div").forEach((el) => {
        el.classList.add("slide-in");
      });
    } else {
      ref.current.querySelectorAll("div").forEach((el) => {
        el.classList.remove("slide-in");
      });
    }
  }, [isIntersecting]);

  const x = require("./x.mp3");
  const y = require("./y.mp3");
  const z = require("./z.mp3");

  const xImage = require("./x.jpg")
  const yImage = require("./y.jpg")
  const zImage = require("./z.jpg")

  const xPackage = {
    trackT : x,
    imageT : xImage,
    descT : "Gymnopedie #1.1"
  }

  const yPackage = {
    trackT : y,
    imageT : yImage,
    descT : "Piano de la Sol"
  }

  const zPackage = {
    trackT : z,
    imageT : zImage,
    descT : "Hymn"
  }

  const tracks = [xPackage, yPackage, zPackage]

  const [count, setCount] = useState(0);

  const rotate = () => {
      if (count === tracks.length - 1) {
        setCount(0);
      } else {
        setCount(count + 1);
      }
  }

    return (
<div>
<FadeIn transitionDuration={1500}>
<div className="row centeralign  music-page">
        <div className="col-lg-12 rightaligned">
<div className="centerleft">
          <h1 className="body-titles">I write music sometimes,</h1>
          <h1 className="body-titles">check it out —</h1>

          <br/>
          </div>
          </div>
          </div>
          </FadeIn>
<div className="foreground">
<div className="relativeclassgeneral dropcard" ref={ref}>
    <div className="container-fluid slide-off">
      <div className="row centeralign">
      <div className="col-md-4">
          <br/>
        </div>
        <div className="col-lg-4">
          <MusicPlayer trackData={tracks[count]} imageAlt="sountrack" updateFunc={rotate}/>
          <br/>
        </div>
        <div className="col-md-4">
          <br/>
        </div>
      </div>
    </div>
  </div>
    <Parallax specificClass="parallax-music"/>
</div>
</div>
    );
}

export default Music;