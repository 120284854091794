import React, { useEffect, useRef, useState } from 'react';
import Parallax from './Parallax';
import FadeIn from "react-fade-in";

function Home() {


  const [isIntersecting, setIsIntersecting] = useState(false);

  const ref = useRef(null);


  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    },
    { rootMargin: "0px" }
  );
  observer.observe(ref.current);
  return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      ref.current.querySelectorAll("div").forEach((el) => {
        el.classList.add("slide-in");
      });
    } else {
      ref.current.querySelectorAll("div").forEach((el) => {
        el.classList.remove("slide-in");
      });
    }
  }, [isIntersecting]);



    return (
<div>
<FadeIn transitionDuration={1500}>
<div className="relativeclassgeneral">
    <div className="container-fluid">
      <div className="row centeralign">
        <div className="col-lg-12 rightaligned">
        <div className="centerleft">
          <h1 className="body-titles">Hi,</h1>
          <span className="body-titles">I'm</span><span className="body-titles highlight-isaac"> Isaac</span>
          <h3 className="subtitles">Final year engineering undergraduate — specialising in software.</h3>
          <h3 className="subtitles">Hobbyist composer.</h3>
          <br/>
          </div>
          <div>
            <a href="https://www.linkedin.com/in/isaac-lombard-96377928a/" target="_blank"><img className="imagelink" src="images/linkedin.png"/></a>
            <a href="https://github.com/William-Rozsa" target="_blank"><img className="imagelink" src="images/github.png"/></a>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div className="emptyspace" />
      </FadeIn>
      <Parallax specificClass="parallax-dude"/>
      <div ref={ref}>
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-1"></div>
        <div className="col-lg-6 off-center slide-off">
        <h2 className="subbody-titles">Education</h2>
          <br/>
          <div className="row mx-0 px-0">
            <div className="col-xl-6 my-2">
          <div className="card">
            <img src="images/usyd.jpg" className="card-img-top" alt="..."/>
            <div className="card-body">
              <h5 className="card-title highlight-isaac"><em>Current</em><br/></h5><h5 className="card-title">BEng(Software) & Honours</h5>
              <h5 className="card-title institutiontitle">University of Sydney</h5>
              <p className="card-text">Strategy, design, coding quality and management. Majoring in Computer Engineering.</p>
              <a href="https://www.sydney.edu.au/courses/courses/uc/bachelor-of-engineering-honours-software-engineering1.html" className="btn btn-outline-dark">Course information</a>
              <hr className='formathr'/>
              <a href="#" className="btn btn-outline-dark disabled">Request course list + results</a>
            </div>
            </div>
          </div>
          <div className="col-xl-6 my-2">
          <div className="card">
            <img src="images/computer2.jpg" className="card-img-top" alt="..."/>
            <div className="card-body">
              <h5 className="card-title">Other Learning</h5>
              <ul>
                <li>
                  <p className="card-text">iOS & Swift - The Complete iOS App Development Bootcamp <br/>(2023)</p>
                </li>
                <li>
                  <span className="card-text">The Complete Web Development Bootcamp <br/>(2023)</span>
                </li>
              </ul>
              <div className="paddedcard">
                <br/>
              </div>
              <a href="#" className="btn btn-outline-dark disabled">More information</a>
            </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col-lg-5 off-center slide-off">
        <div className='inline-block'>
          <h2 className="subbody-titles">Skillset</h2>
          <br/>
          <ul className="list-group w-90">
            <li className="list-group-item"><i className="fa-solid fa-terminal"></i> API Development</li>
            <li className="list-group-item"><i className="fa-solid fa-code"></i> Web Development</li>
            <li className="list-group-item"><i className="fa-brands fa-apple"></i> iOS Development</li>
            <li className="list-group-item"><i className="fa-solid fa-folder-open"></i> Software Design Techniques</li>
            <li className="list-group-item"> <i className="fa-solid fa-rectangle-ad"></i> Social Media Marketing</li>
            <li className="list-group-item"> <i className="fa-solid fa-file-audio"></i> Music and SFX</li>
            <li className="list-group-item"> <i className="fa-solid fa-earth-asia"></i> English | Japanese</li>
          </ul>
          <br/>
          <br/>
          <br/>
          {/* <h2 className="subbody-titles">Professional Experience</h2>
          <br/>
          <ul className="list-group w-90">
            <li className="list-group-item"> <b>Social Media Manager</b>, Lombard and Laurence Lawyers <br/> 
              <i>August 2021 - January 2022 <br/> Part time</i>
              <br/>
            </li>
            <li className="list-group-item"><b>Labourer</b>, Simon & Simon Plumbers <br/> 
                <i>2020 – 2022 <br/> Casual</i>
                <br/>
              </li>
              <li className="list-group-item"> <b>Paralegal, Assistant</b>, George Lombard Consultancy <br/> 
                <i>June 2020 - August 2021 <br/> Part time</i>
              </li>
            <li className="list-group-item"><b>Food Runner, Shift Leader and Expediter</b>, Cafe Sydney <br/> 
              <i>January 2019 - October 2019 <br/> Full time</i>
              <br/>
              <i/>
            </li>
          </ul> */}
          </div>
        </div> 
        </div>
        </div>
        </div>
        <div className='floating-background'>
        <Parallax specificClass="parallax-squares"/>
        </div>
</div>
    );
}

export default Home;