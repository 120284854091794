import React, {useState } from 'react';


function Vim(props) {

    const [value, setValue] = useState("");
    function handleUpdate(event) {
        const newVal = event.target.value;
        setValue(newVal);
    }

    window.addEventListener("keyup", ({key}) => {
        if (key === "Enter") {
            if (value === ":q!" | value === ":q") {
                props.returnCommand();
            }
        }
    });

        return (<div>
            <div className='appearing-text'>
            systemInput ~ $ <input name="command" id="command" value={value} onChange={handleUpdate} autoFocus/>
            <br/>
            <p>Exit with ':q!' or ':q'.</p>
            </div>
            </div>);
            
}

export default Vim;